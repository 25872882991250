import Vue from "vue";
import Vuex from 'vuex'
import {AccountsClient, PawsClient, addToken, StatsClient} from './PawApi'
import {dateFormat} from './dates'
import {PAWStatus} from "./PAWstates";

Vue.use(Vuex);

const SNACKBAR_DURATION_STD = 3000;

// let apiUrl = "https://78.47.117.162:5001"
let apiUrl = "https://" + window.location.host;
// let apiUrl = "https://" + window.location.hostname + ":5001";
let ac = new AccountsClient(apiUrl)
let pc = new PawsClient(apiUrl)
let sc = new StatsClient(apiUrl)

const store = new Vuex.Store({
    state: {
        user: null,
        partners: [],
        partners_details: [],
        foreignUser: null,

        paws: [],
        paw_detail: null,
        ppsConfig: null,
        paws_claimed: [],
        paws_claimed_byCompanies: [],

        textSnackbar: 'Noch kein Anmeldeversuch!',
        showSnackbar: false,
        rxtx: 0,
        stats: {
            partner: -1,
            customers: -1,
        },
        rxtxText: "",
    },
    actions: {
        requestPasswordResetMail(context, username){
            return ac.forgotPassword(username)
        },
        requestPasswordChange(context, req){
            return ac.resetPassword(req.email, req.password, req.confirmPassword, req.token)
        },
        verification(context, req){
            return ac.confirmEmail(req.mail, req.key)
        },
        getPDF(context, req){
            return pc.downloadPdf(req.pawId, req.ppsId).then((result) => {
                return Promise.resolve(result)
            })
        },
        showSnackbar(context, payload) {
            context.commit("setSnackbar", {text: payload.text, enable: true})
            if (!payload.duration) {
                payload.duration = SNACKBAR_DURATION_STD
            }
            setTimeout(() => {
                context.commit("setSnackbar", {text: "Kein Status", enable: false})
            }, payload.duration)
        },
        async loadPaws(context) {
            let succeeded = true
            try {
                await pc.getAllPaws().then(function (result) {
                    context.commit("setPaws", result)
                }).catch(() => {
                    succeeded = false
                })
            } catch (e) {
                printSnackbar(context, "Laden der PAWs fehlgeschlagen")
            }
            return succeeded ? Promise.resolve : Promise.reject("Failed on loadPaws")
        },
        loadPawDetails(context, id) {
            if (context.state.paw_detail && context.state.paw_detail.pawId === id) {
                //console.log("paw details already loaded: ")
                //console.log(context.state.paw_detail)
                return Promise.resolve()
            } else {
                context.commit("setRXTX", true)
                return pc.getPawById(id).then(function (result) {
                        result.pawId = id
                        context.commit("setPAWDetails", result)
                    }
                ).finally(() => {
                    context.commit("setRXTX", false)
                })
            }
        },
        reloadPawDetails(context, id) {
            context.commit("setRXTX", true)
            return pc.getPawById(id).then(function (result) {
                    result.pawId = id
                    context.commit("setPAWDetails", result)
                }
            ).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        loadPpsConfig(context, ids){
            return pc.getPawPicSetConfig(ids.pawId, ids.ppsId).then( result => {
                console.log(result)
                context.commit("setPawPicConfig", result)
            })
        },
        createPAW(context, paw) {
            paw.gender = parseInt(paw.gender)
            if(paw.weight && paw.weight.toString().length > 0)
                paw.weight = parseInt(paw.weight)
            if(paw.height && paw.height.toString().length > 0)
                paw.height = parseInt(paw.height)
            context.commit("setRXTX", true)
            return pc.create(paw).then(result => {
                let newPaw = {
                    pawId: result.toString(),
                    description: paw.description,
                    status: 0
                }
                context.commit("addToPawList", newPaw)
                return Promise.resolve(result)
            }).catch(reason => {
                context.dispatch("showSnackbar", {text: "Es ist ein Übertragungsfehler aufgetreten."})
                return Promise.reject(reason)
            }).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        deletePAW(context, id) {
            pc.delete(id).then(() => {
                context.dispatch("loadPaws")
            })
        },
        async offerPaw(context, offerReq) {
            await pc.offerToPawPartner(offerReq.pawId, offerReq).then(() => {
                context.commit("setPawStatus", {id: offerReq.pawId, status: 1})
            })
        },
        async claimPaw(context, id) {
            return pc.claimPaw(id).then(() => {
                context.commit("setPawStatus", {id: id, status: PAWStatus.claimed})
            })
        },
        async rejectPaw(context, req) {
            return pc.rejectPaw(req.pawId, req.reason).then(() => {
                context.commit("setPawStatus", {id: req.pawId, status: PAWStatus.rejected})
            })
        },
        async reworkPaw(context, req) {
            return pc.reworkPaw(req.pawId, req.reason).then(() => {
                context.commit("setPawStatus", {id: req.pawId, status: PAWStatus.rework})
            })
        },
        async markAsDone(context, id) {
            return pc.donePaw(id).then(() => {
                context.commit("setPawStatus", {id: id, status: PAWStatus.done})
            })
        },
        sendPawConfig(context, req) {
            return pc.createPawPicSetConfig(req.pawId, req.pawPicSetId, req)
        },
        addPawPicsUsingProgressBar(context, pawPics) { // TODO
            context.commit("setRXTX", true)
            // eslint-disable-next-line no-unused-vars
            return new Promise(function (resolve) {
                var url_ = apiUrl + "/api/Paws/{pawId}/pawpicsets";
                url_ = url_.replace("{pawId}", encodeURIComponent("" + pawPics.pawId));
                url_ = url_.replace(/[?&]$/, "");

                var content_ = JSON.stringify(pawPics);
                var options_ = {
                    body: content_,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                };
                options_ = addToken(options_)


                let xhr = new XMLHttpRequest()
                xhr.open("POST", url_, true)
                xhr.setRequestHeader("Content-Type", "application/json")
                xhr.setRequestHeader("Accept", "application/json")
                xhr.setRequestHeader("Authorization", options_.headers.Authorization)

                let upl = xhr.upload
                upl.onprogress = function (e) {
                    if (e.lengthComputable) {
                        let p = e.loaded / e.total * 100
                        if(pawPics.percent){
                            p = p * ((pawPics.percent.end - pawPics.percent.start) / 100) + pawPics.percent.start
                        }
                        p = Math.round(p)
                        const perc = "" + p + "%"
                        context.commit("setRXTXtext", "Gesendet: " + perc)
                        console.log(perc)
                    }
                }
                upl.onloadstart = function () {
                    // nothing to do
                }
                upl.onloadend = function () {
                    let text = "Server arbeitet..." + "     " + "Gesendet: " + pawPics.percent.end + "%"
                    context.commit("setRXTXtext", text)
                }

                xhr.onload = function () {
                    if (this.status >= 200 && this.status < 300) {
                        resolve(JSON.parse(xhr.responseText))
                    } else {
                        throw new Error("An unexpected server error occurred.")
                    }
                };

                xhr.send(options_.body)
            }).then(async function (result) {
                    if(!pawPics.percent || pawPics.percent.end === 100) // only show after last upload
                        context.commit("setRXTXtext", "Fertigstellen...")

                    // Add images locally
                    const pps = {
                        pawId: pawPics.pawId,
                        id: result,
                        created: dateFormat(new Date(), "isoUtcDateTime"),
                        feetType: pawPics.feetType,
                        feet: pawPics.feet,
                        imprint: pawPics.imprint,
                    }
                    context.commit("addPawPics", pps)

                    // change PAW status
                    for (let elem of context.state.paws) {
                        if (elem.pawId === pawPics.pawId) {
                            if (elem.status === PAWStatus.created) {
                                context.commit("setPawStatus", {id: pawPics.pawId, status: PAWStatus.imgsadded})
                            }
                            break;
                        }
                    }
                }
            ).catch(reason => {
                context.dispatch("setSnackbar", {text: "Es ist ein Fehler beim Senden aufgetreten."})
                return Promise.reject(reason)
            }).finally(() => {
                context.commit("setRXTX", false)
                context.commit("setRXTXtext", "")
            })
        },
        deletePartner(context, id){
           return ac.delete(id).then(() => {
               context.commit("delPartner", id)
           })
        },
        addPawPics(context, pawPics) {
            context.commit("setRXTX", true)
            return pc.createPawPicSet(pawPics.pawId, pawPics)
                .then(async function (result) {
                        // Add images locally
                        const pps = {
                            pawId: pawPics.pawId,
                            id: result,
                            created: dateFormat(new Date(), "isoUtcDateTime"),
                            feetType: pawPics.feetType,
                            feet: pawPics.feet,
                            imprint: pawPics.imprint,
                        }
                        context.commit("addPawPics", pps)

                        // change PAW status
                        for (let elem of context.state.paws) {
                            if (elem.pawId === pawPics.pawId) {
                                if (elem.status === PAWStatus.created) {
                                    context.commit("setPawStatus", {id: pawPics.pawId, status: PAWStatus.imgsadded})
                                }
                                break;
                            }
                        }
                    }
                ).catch(reason => {
                    context.dispatch("setSnackbar", {text: "Es ist ein Fehler beim Senden aufgetreten."})
                    return Promise.reject(reason)
                }).finally(() => {
                    context.commit("setRXTX", false)
                })
        },
        editUserData(context, user){
            context.commit("setRXTX", true)
            return ac.update(user.id, user).then(result => {
                user.company.companyId = context.state.user.company.companyId
                context.commit("editUserData", user)
                return Promise.resolve(result)
            }).catch((reason) => {
                context.dispatch("showSnackbar", {text: "Es ist ein Fehler aufgetreten."})
                return Promise.reject(reason)
            }).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        async createUser(context, user) {
            context.commit("setRXTX", true)
            return ac.register(user).then(function () {
                context.dispatch("showSnackbar", {text: "Registrierung erfolgreich.", duration: 3000})
                return Promise.resolve()
            }).catch(function (reason) {
                context.dispatch("showSnackbar", {text: "Es ist ein Fehler aufgetreten.", duration: 3000})
                return Promise.reject(reason)
            }).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        async logIn(context, credentials) {
            context.commit("setRXTX", true)
            localStorage.clear()
            return ac.login(credentials).then(async function (result) {
                context.dispatch("showSnackbar", {text: "Anmeldung erfolgreich. Daten werden geladen."}).finally()

                if(credentials.stayLoggedIn) { // User wants to stay logged in.
                    await localStorage.setItem("token", result.token)
                    await localStorage.setItem("id", result.id)
                } else { // User wants to be logged out.
                    await sessionStorage.setItem("token", result.token)
                    await sessionStorage.setItem("id", result.id)
                }
                let role = null

                await ac.getById(result.id).then(function (result) {
                    context.commit("setUser", result);
                    role = result.role
                }).catch((reason) => {
                    return Promise.reject(reason)
                })

                if(role === 0){ // Admin
                    await context.dispatch("loadAdminData")
                } else { // Partner & User
                    await pc.getPaws().then(function (result) {
                        context.commit("setPaws", result);
                    }).catch( reason => {
                        return Promise.reject(reason)
                    })
                }
                return Promise.resolve()
            }).catch(function (reason) {
                context.dispatch("showSnackbar", {text: "Es ist ein Fehler aufgetreten.", duration: 3000})
                console.log(reason)
            }).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        async attemptJwtLogin(context) {
            let token;
            let id;

            // try sessionStorage first
            token = sessionStorage.getItem("token")
            id = sessionStorage.getItem("id")

            // maybe in localStorage?
            if(!id || !token) {
                token = localStorage.getItem("token")
                id = localStorage.getItem("id")
            }

            if (!id || !token) {
                // no jwt Login possible, skipping jwt login
                localStorage.clear();
                sessionStorage.clear();
                return Promise.reject("No ID or token found.");
            }

            context.commit("setRXTX", true) // staring data exchange
            return ac.getById(id).then(
                async function (result) {
                    context.commit("setUser", result)
                    context.dispatch("showSnackbar", {text: "Automatische Anmeldung erfolgreich!"}).then()

                    if(result.role === 0){ // Admin
                        await context.dispatch("loadAdminData")
                    } else { // Partner & User
                        await pc.getPaws().then(function (result) {
                            context.commit("setPaws", result);
                        }).catch( reason => {
                            return Promise.reject(reason)
                        })
                    }
                    return Promise.resolve()
                }
            ).catch( reason => {
                localStorage.clear();
                sessionStorage.clear();
                return Promise.reject(reason)
            }).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        async loadAdminData(context){
            const c = pc.getClaims().then(value => {
                context.commit("setPawsClaimed", value)
            }).catch( reason => {
                return Promise.reject(reason)
            })
            const p = ac.getAll().then(result => {
                context.commit("setPartners", result)
            }).catch( reason => {
                return Promise.reject(reason)
            })
            await context.dispatch("loadAdminStats")
            await c
            await p

            for(let partner of context.state.partners){
                ac.getById(partner.id).then(result => {
                    context.commit("addPartnersDetails", result)
                })
            }
        },
        async loadUserDetails(context, id) {
            if (!id) {
                id = context.state.user.id
            }
            console.log("loading details for user " + id)
            await ac.getById(id).then(value => {
                context.commit("setUser", value)
                console.log(value)
            }).catch(reason => {
                console.log(reason)
            })
        },
        async loadPartnerDetails(context, id) {
            await ac.getById(id).then(value => {
                context.commit("setForeignUser", value)
                console.log(value)
            }).catch(reason => {
                console.log(reason)
            })
        },
        loadAllPartners(context) {
            if (!context.state.user) { // Abbruch wenn nicht authentifiziert.
                return
            }
            ac.getAll().then(value => {
                context.commit("setPartners", value)
            })
        },
        async logout(context) {
            context.commit("reset");
            localStorage.clear();
            sessionStorage.clear();
            await context.dispatch("showSnackbar", {text: "Abgemeldet!"})
        },
        activatePartner(context, id){
            context.commit("setRXTX", true)
            ac.activate(id).then(() => {
                context.commit("setPartnerActivation", {id, act: true})
            }).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        deactivatePartner(context, id){
            context.commit("setRXTX", true)
            ac.deactivate(id).then(() => {
                context.commit("setPartnerActivation", {id, act: false})
            }).finally(() => {
                context.commit("setRXTX", false)
            })
        },
        loadAdminStats(context){
            sc.getUserStats().then((result) => {
                context.commit("setAdminStats", result)
            })
        }
    },
    mutations: {
       delPartner(state, id){
            for(let p of state.partners){
                if(p.id === id){
                    state.partners.splice(state.partners.indexOf(p), 1)
                }
            }
        },
        setAdminStats(state, stats){
            state.stats = stats
        },
        addPartnersDetails(state, partner){
            state.partners_details.push(partner)
        },
        editUserData(state, user){
            for(let e in user){
                state.user[e] = user[e]
            }
        },
        setPartnerActivation(state, req){
            for(let elem of state.partners){
                if(elem.id === req.id){
                    elem.isActivated = req.act
                    break;
                }
            }
            if(state.foreignUser && state.foreignUser.id === req.id){
                state.foreignUser.isActivated = req.act
            }
        },
        setPawPicConfig(state, ppsConfig){
            state.ppsConfig = ppsConfig
        },
        addPawPics(state, payload) {
            state.paw_detail.pawPicSets.push(payload)
        },
        delPawPics(state, payload) {
            const index = state.paw_detail.pawPicSets.indexOf(payload)
            state.paw_detail.pawPicSets.splice(index, 1)
        },
        addToPawList(state, paw) {
            state.paws.push(paw)
        },
        setPawStatus(state, payload) {
            const id = payload.id
            const stat = payload.status
            for (let elem of state.paws) {
                if (elem.pawId === id) {
                    elem.status = stat
                }
            }
            if(state.paw_detail && state.paw_detail.id === payload.id){
                state.paw_detail.status = stat
            }
        },
        setRXTX(state, payload) {
            if(payload) {
                state.rxtx += 1
            } else if (!payload) {
                state.rxtx -= 1
            } else {
                // do nothing
            }
        },
        setRXTXtext(state, payload) {
            state.rxtxText = payload
        },
        setSnackbar(state, payload) {
            state.textSnackbar = payload.text
            state.showSnackbar = payload.enable
        },
        setPaws(state, paws) {
            state.paws = paws
        },
        setPawsClaimed(state, claims) {
            let result = []
            for(let company of claims){
                if(!company.claimedPaws)
                    continue;
                for(let claim of company.claimedPaws){
                    claim.partnerId = company.id
                    claim.companyName = company.companyName
                    result.push(claim)
                }
            }
            state.paws_claimed = result
            state.paws_claimed_byCompanies = claims
        },
        setPAWDetails(state, paw_detail) {
            state.paw_detail = paw_detail
        },
        setUser(state, user) {
            state.user = user
        },
        setForeignUser(state, user) {
            user.created = dateFormat(user.created, "myDate")
            user.updated = dateFormat(user.updated, "myDate")
            if(user.company) {
                user.company.pq_since = dateFormat(user.company.pq_since, "myDate")
                user.company.pq_until = dateFormat(user.company.pq_until, "myDate")
            }
            state.foreignUser = user
        },
        setPartners(state, partners) {
            state.partners = partners
        },
        reset(state) {
            state.user = null;
            state.partners = [];
            state.paws = [];
            state.paws_claimed = [];
            state.paw_detail = null;
            state.textSnackbar = 'Noch kein Anmeldeversuch!';
            state.showSnackbar = false;
            state.rxtx = 0
        }
    }
});

const snackbarDuration = 3000

// eslint-disable-next-line no-unused-vars
function printSnackbar(context, message) {
    context.dispatch("showSnackbar", {text: message, duration: snackbarDuration})
}

export default store

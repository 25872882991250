/**
 * Status:
 * 0: created
 * 1: offered
 * 2: rejected
 * 3: claimed
 * 4: rework
 * 5: done
 * 6: imgs added
 */

const PAWStatus = {
    created: 0,
    offered: 1,
    rejected: 2,
    claimed: 3,
    rework: 4,
    done: 5,
    imgsadded: 6,
}

const codenames = [
    "created",
    "offered",
    "rejected",
    "claimed",
    "rework",
    "done",
    "imgsadded",
]

const statusList = [
    "erstellt",
    "beauftragt",
    "abgelehnt",
    "angenommen",
    "ausbessern",
    "erledigt",
    "bebildert",
]

const toDescription = function (status = 0) {
    return statusList[status]
}

const toCodename = function (status = 0) {
    return codenames[status]
}

const offerable = function (status){
    return status === PAWStatus.imgsadded || status === PAWStatus.rejected
}

const picsAddable = function (status){
    return status === PAWStatus.created || status === PAWStatus.rejected || status === PAWStatus.rework
        || status === PAWStatus.imgsadded
}

export {PAWStatus}
export {toDescription, toCodename}
export {offerable, picsAddable}

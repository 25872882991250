<template>
    <div id="app">
        <a class="skip-link" href="#main">Zum Inhalt springen!</a>
        <br>&nbsp;
        <br>&nbsp;
        <br>&nbsp;
        <Header/>
        <div id="main-wrapper">
            <router-view id="main"></router-view>
        </div>
        <Footer/>

        <md-snackbar :md-active="$store.state.showSnackbar">{{ $store.state.textSnackbar }}</md-snackbar>

        <!--<loading :active.sync="sending" :canCancel="false" :is-full-page="true"></loading>-->
        <vue-element-loading :active.sync="sending" :is-full-screen="true" spinner="spinner" color="#000000" :text="spinnerText"/>

        <!-- TODO: Dialog auslagern -->
        <md-dialog :md-active.sync="showCookieDial" :md-click-outside-to-close="false" :md-close-on-esc="false"
                   class="cookieDial">
            <md-dialog-title>Cookies?</md-dialog-title>
            <md-dialog-content>
                Um PAWblueprint nutzen zu können müssen Sie der Nutzung von Cookies zustimmen.
                <ol>
                    <li>
                        <b>cookie-consent-vX</b>
                        <br>
                        Dieses Cookie speichert die Zustimmung des Nutzers zur Benutzung von Cookies. Das X im Namen des Cookies
                        ist eine
                        Versionsnummer. Bei Änderungen an den
                        Cookie-Richtlinien dieser Webseite werden Sie erneut aufgefordert der Benutzung von Cookies zuzustimmen.
                    </li>
                    <li>
                        <b>refreshToken:</b>
                        <br>
                        Dieses Cookie dient der Authentifizierung gegenüber dem Server. Es sorgt dafür, dass Sie
                        über beispielsweise einen Tab- oder Fensterwechsel hinweg angemeldet bleiben.
                    </li>
                </ol>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised md-primary" v-on:click="cookieConsent()">Akzeptieren</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
const Header = () => import("./components/All_Header");
const Footer = () => import("./components/All_Footer");
const Loading = () => import("vue-loading-overlay");
const VueElementLoading = () => import("vue-element-loading");
import 'vue-loading-overlay/dist/vue-loading.css';

// eslint-disable-next-line no-unused-vars
const COOKIE_VERSION = 1

export default {
    name: 'app',
    components: {
        Header,
        Footer,
        // eslint-disable-next-line vue/no-unused-components
        Loading,
        VueElementLoading
    },
    data: () => ({
        showCookieDial: false,
    }),
    computed: {
        nutzer_art: function () {
            let role = null
            let p = this.$route.path
            p = p.substring(1, p.indexOf("/", 1))
            role = p.length > 2 ? p : null;
            if (role === 'admin' || role === 'partner' || role === 'user') {
                //
            } else {
                role = 'user'
            }
            return role
        },
        sending: function () {
            return this.$store.state.rxtx > 0
        },
        spinnerText: function () {
            return this.$store.state.rxtxText
        }
    },
    mounted() {
        // global settings
        this.$material.locale.dateFormat = "dd.MM.yyyy";

        // check for cookie-consent
        /*if (!this.$cookies.isKey("cookie_consent_v" + COOKIE_VERSION)) {
            this.showCookieDial = true
        }*/ // Cookie-Dialog ausgeschaltet!

        // try to log in with jwt from localStorage
        // eslint-disable-next-line no-constant-condition
        this.$store.dispatch("attemptJwtLogin").then(() => {
            // this.$router.push("/" + this.$store.state.user.role + "/dashboard/");
            // TODO einkommentieren ???
        }).catch(() => {
            // do nothing
        })
    },
    methods: {
        /*cookieConsent() {
            let val = (new Date()).toLocaleDateString() + "-"
                + (new Date()).toLocaleTimeString().replace(":", ".").replace(":", ".");
            this.$cookies.set("cookie_consent_v" + COOKIE_VERSION, val, "1y",
                null, null, null, "strict");
            if (this.$cookies.isKey("cookie_consent_v" + COOKIE_VERSION)) {
                this.showCookieDial = false;
            }
        }*/
    }
}
</script>

<style lang="scss">
.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000000;
    color: white;
    padding: 8px;
    z-index: 100;
}

#main-wrapper {
    background-image: url("assets/background.jpg");
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 20px;
}

#main {

}

.cookieDial {
    z-index: 100;
}

.skip-link:focus {
    top: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 0;
}

@import "~vue-material/dist/theme/engine"; // Import the theme engine
@include md-register-theme("default", (
    //primary: #00696d, // The primary color of your brand
    primary: #a6c532,
    accent: #b34900, // The secondary color of your brand
    theme: light,
    /*button: yellow,
    text-primary-on-background: white,
    text-primary-on-accent: red,*/
));
/*@import "~vue-material/dist/theme/all"; // Apply the theme*/
@import "~vue-material/dist/components/MdButton/theme";
@import "~vue-material/dist/components/MdContent/theme";
@import "~vue-material/dist/components/MdTabs/theme";

.md-button.md-theme-default {
    color: white;
}
</style>

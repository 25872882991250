let dateFormat = require('dateformat');
dateFormat.i18n = {
    dayNames: [
        "So",
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa",
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
    ],
    monthNames: [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
    timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};
dateFormat.masks.myDate = 'dd.mm.yyyy';
dateFormat.masks.myTime = 'HH:MM:ss';
dateFormat.masks.myDateTime = 'dd.mm.yyyy HH:MM:ss';
export {dateFormat};

const toDate = (function(value){
    try {
        let parts = value.split(".")
        if(parts[2].length !== 4)
            return undefined;
        if(parts[1].length !== 2)
            return undefined;
        if(parts[0].length !== 2)
            return undefined;
        return new Date(parseInt(parts[2]), parseInt(parts[1])-1, parseInt(parts[0])+1)
    } catch (e) {
        return undefined
    }
})()
export {toDate}

import Vue from 'vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import App from "./App";
import router from './helpers/router'
import store from './helpers/storage'
import Vuelidate from 'vuelidate'
import WebCam from 'vue-web-cam'
//import Loading from "vue-loading-overlay";
//import "vue-loading-overlay/dist/vue-loading.css"

Vue.use(WebCam);
Vue.use(Vuelidate);
Vue.use(VueMaterial);
//Vue.use(Loading)

Vue.config.productionTip = true;

// noinspection ObjectAllocationIgnored
new Vue({
    el: '#app',
    router: router,
    template: '<App/>',
    components: {App},
    store: store
});

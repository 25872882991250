/* Vue */
import Vue from "vue";
import Router from "vue-router";

/* Components: dynamic imports */

const User_Start = () => import("../components/User_Start");
const All_Registration = () => import("../components/All_Registration");
const User_Dashboard = () => import("../components/User_Dashboard");
const User_PAW_detail = () => import("../components/User_PAW_detail");
const User_PAW_new = () => import("../components/User_PAW_new");
const All_Imprint = () => import("../components/All_Imprint");
const All_PrivacyPolicy = () => import("../components/All_PrivacyPolicy");
const User_CameraDialog = () => import("../components/User_CameraDialog");
const User_Data = () => import("../components/User_Data");
const Partner_Start = () => import("../components/Partner_Start");
const Partner_Dashboard = () => import("../components/Partner_Dashboard");
const Partner_Data = () => import("../components/Partner_Data");
const Partner_PAW_detail = () => import("../components/Partner_PAW_detail");
const Admin_Start = () => import("../components/Admin_Start");
const Admin_Dashboard = () => import("../components/Admin_Dashboard");
const TMP = () => import("../components/TMP");
const User_PAW_addImages = () => import("../components/User_PAW_addImages");
const Admin_Partner_detail = () => import("../components/Admin_Partner_detail");
const User_PAW_offer = () => import("../components/User_PAW_offer");
const All_SetNewPassword = () => import("../components/All_SetNewPassword");
const All_RequestPasswordReset = () => import("../components/All_RequestPasswordReset");
const All_Licences = () => import("../components/All_Licences");
const Partner_PAW_Picset_edit = () => import("../components/Partner_PAW_Picset_edit");
const All_FAQ = () => import("../components/All_FAQ");
const User_Data_edit = () => import("../components/User_Data_edit");
const Partner_Data_edit = () => import("../components/Partner_Data_edit");
const All_Philosphy = () => import("../components/All_Philosphy");
const All_EmailVerification = () => import("../components/All_EmailVerification");
const All_Terms = () => import("../components/All_Terms");

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            component: User_Start
        },
        {
            path: '/register/:target',
            component: All_Registration
        },
        {
            path: '/register/',
            component: All_Registration
        },
        {
            path: '/register/verify/:id',
            component: All_Registration
        },
        {
            path: '/user/',
            component: User_Start
        },
        {
            path: '/user/dashboard/',
            component: User_Dashboard
        },
        {
            path: '/user/my_data/',
            component: User_Data
        },
        {
            path: '/user/my_data/edit/',
            component: User_Data_edit
        },
        {
            path: '/user/paw/detail/:pawId',
            component: User_PAW_detail
        },
        {
            path: '/user/paw/new/',
            component: User_PAW_new
        },
        {
            path: '/user/paw/addimages/:pawId/:leftright',
            component: User_PAW_addImages
        },
        {
            path: '/user/paw/addimages/:pawId/',
            redirect: '/user/paw/addimages/:pawId/-1'
        },
        {
            path: '/user/paw/offer/:pawId',
            component: User_PAW_offer
        },
        {
            path: '/partner/',
            component: Partner_Start
        },
        {
            path: '/partner/dashboard/',
            component: Partner_Dashboard
        },
        {
            path: '/partner/my_data/',
            component: Partner_Data
        },
        {
            path: '/partner/my_data/edit/',
            component: Partner_Data_edit
        },
        {
            path: '/partner/paw/:pawId/editPics/:picIdx',
            component: Partner_PAW_Picset_edit
        },
        {
            path: '/partner/paw/detail/:pawId',
            component: Partner_PAW_detail
        },
        {
            path: '/admin/',
            component: Admin_Start
        },
        {
            path: '/admin/dashboard/',
            component: Admin_Dashboard
        },
        {
            path: '/admin/partner/detail/:id',
            component: Admin_Partner_detail
        },
        {
            path: '/imprint/',
            component: All_Imprint
        },
        {
            path: '/privacypolicy/',
            component: All_PrivacyPolicy
        },
        {
            path: '/reset/password/',
            component: All_RequestPasswordReset
        },
        {
            // Invalid Route --> Catch
            path: '/reset/password/:trash',
            component: All_SetNewPassword
        },
        {
            path: '/reset/password/:mail/:key',
            component: All_SetNewPassword
        },
        {
            path: '/activation/',
            component: All_EmailVerification
        },
        {
            path: '/activation/:trash',
            component: All_EmailVerification
        },
        {
            path: '/activation/:mail/:key',
            component: All_EmailVerification
        },
        {
            path: '/cam/',
            component: User_CameraDialog
        },
        {
            path: '/licences/',
            component: All_Licences
        },
        {
            path: '/faq/',
            component: All_FAQ
        },
        {
            path: '/philosophy/',
            component: All_Philosphy
        },
        {
            path: '/terms/',
            component: All_Terms
        },
        {
            path: '/tmp/',
            component: TMP
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
});

export default router
